import styled from "styled-components";
import Favicon from "../assets/hangloose.svg"

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 8px;
  padding: .5rem 0 .5rem .5rem;
  text-align: center;
`;

const TextStyled = styled.p`
  color: #ffffff !important;
  flex: 7;
`;

const SpanStyled = styled.span`
  font-family: "Gotham-Medium" !important;
  color: gray !important;
`;

interface ModalOptionsProps {
  message?: string;
  deposit?: string;
}

const ModalOptions: React.FC<ModalOptionsProps> = ({ message, deposit }) => {
  return (
    <div className="px-[3%] w-full md:px-[10%]">
      <OptionContainer className="justify-center">
        <div className="flex-1 flex justify-center"><img className="flex-1 w-full max-w-[40px]" src={Favicon} alt="betvera" /></div>
        <TextStyled>
          {message && message} | <SpanStyled>{deposit && deposit}</SpanStyled>
        </TextStyled>
      </OptionContainer>
    </div>
  );
};

export default ModalOptions;
