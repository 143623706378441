import React from 'react'
import styled from 'styled-components'
import ModalOptions from '../ModalOptions/ModalOptions'
import whiteLogo from "../assets/white-logo.svg"
import headerImage from '../assets/modal/header.webp'
import Offer from '../Offer'

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99900000;
`

const ModalContent = styled.div`
  height: auto;
  min-height: 60vh;
  max-height: 100vh;
  width: 95dvw;
  border-radius: 24px;
  display: flex;
  flex-direction: column;

  background-color: #001926;
  transition: 0.3s;

  @media screen and (min-width: 768px){
    min-height: 90vh;
    max-width: 450px;
    width: 50vw;
  }

  @media screen and (min-width: 1536px){
    min-height: auto;
    width: 50vw;
  }

  p {
    color: #7d7d84;
    font-family: 'Gotham-Medium';
  }
`

const ImageStyled = styled.img`
  width: 130px;
  height: auto;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;

  a {
    cursor: pointer;
    border-radius: 5px;
    padding: .5rem 0;
    align-content: center;
  }
`

const Modal: React.FC = () => {
  return (
    <ModalOverlay>
      <ModalContent>
        <Offer changeClassName={true} className='w-full'><img className='w-full' src={headerImage} /></Offer>
        <div className='w-full flex-1 text-center flex flex-col my-6 gap-6 justify-between'>
          <div className='flex flex-col gap-4 w-full justify-around'>
            <a href={`//go.betvera.info/?_lp=1&${window.location.search.substring(1)}`}>
              <ModalOptions message={'20 Rodadas Grátis'} deposit={'Deposite R$10'} />
            </a>
            <a href={`//go.betvera.info/?_lp=1&${window.location.search.substring(1)}`}>
              <ModalOptions message={'40 Rodadas Grátis'} deposit={'Deposite R$20'} />
            </a>
            <a href={`//go.betvera.info/?_lp=1&${window.location.search.substring(1)}`}>
              <ModalOptions
                message={'100 Rodadas Grátis'}
                deposit={'Deposite R$100'}
              />
            </a>
          </div>
          <div className='w-full flex items-center justify-center flex-col gap-5'>
            <Buttons>
              <Offer className="font-black animate-scaling uppercase text-[#232426] bg-[#08DE5B]  flex items-center justify-center">
                depositar
              </Offer>
              <Offer className='flex justify-center hover:opacity-60 text-gray-300'>Fechar</Offer>
            </Buttons>
            <Offer>
              <ImageStyled
                src={whiteLogo}
                alt="betvera"
              />
            </Offer>
          </div>
        </div>
      </ModalContent>
    </ModalOverlay>
  )
}

export default Modal
