import React, { useState } from "react";
import ScratchCard from "./ScratchCard/scratchCardGame";
import Background from "./ScratchCard/scratchCardBG";
import Modal from "./Modal/Modal"
import GlobalStyle from "./Fonts/GlobalStyle";
import styled, { keyframes } from "styled-components";
import Counter from "./counter/counter";
import { useLocation } from "react-router-dom";
import Portal from "./assets/portal.png";
import Bottom from "./assets/footer.png"
import Button from "./assets/btnv.avif"
import Raspadinha from "./assets/raspa.png"
import Tag from "./assets/tag-adult.png"

const IframeContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  pointer-events: none
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(4px);
  z-index: 0;
`;

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

const Main = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.75);
  
`;

const Scratch = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 10px;
`;

const CardDiv = styled.div`
  position: absolute;
  top: 0;
  display: block;
  margin: auto;
  width: 100%;
  background-image: url(${Portal});
  background-size: cover;

  @media screen and (min-width: 768px) {
    display: block;
    margin: auto;
  }
`;

const ScratchCardDiv = styled.div`
  margin-top: 140px;
`;

const BackgroundDiv = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonDiv = styled.div`
  background-image: url(${Bottom});
  background-size: 100%;
  aspect-ratio: 425 / 135;
  display: block;
  margin: 15px auto 0px auto;
  z-index: 10000;
  width: 100%;
  transform: translateY(-5%);
  height: 120px;
  border-radius: 0 0 10px 10px;

  button {
    background: none;
    border: none;
    display: block;
    margin: auto;

    img {
      width: 280px;
      display: block;
      margin: auto;
    }
  }

  @media screen and (min-width: 768px) {
    width: 400px;
  }
`;

const StyledTag = styled.img`
  width: 50%;
  display: block;
  margin: auto;
  transform: translateY(-40%);
`;

const scratchAnimation = keyframes`
  0% { transform: rotate(0deg); }
  50% { transform: rotate(-55deg); }
  100% { transform: rotate(0deg); }
`;

const Btn = styled.div`
  background-image: url(${Button});
  width: 310px;
  height: 50px;
  background-size: 100% 100%;
  margin: 5px 0px;
  position: relative;
  animation: pulse 1s infinite;

  @keyframes pulse {
    0% {
      transform: scaleX(1);
    }
    50% {
      transform: scale3d(1.05, 1.05, 1.05);
    }
    to {
      transform: scaleX(1);
    }
  }
`;

// Use the defined keyframes in your styled component
const Hand = styled.img`
  width: 20px !important;
  position: absolute;
  right: 45px;
  top: 15px;
  animation: ${scratchAnimation} 2s infinite;
`;

const App: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [, setScratchedPercent] = useState(0);
  const [animate, setAnimate] = useState(false); // State to control animation
  const [cover] = useState(true);
  const [btn, setBtn] = useState(true);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("_token");

  const handleScratch = (scratched: boolean) => {
    if (scratched) {
      setTimeout(() => {
        setShowModal(true);
        setBtn(false);
      }, 2000);
      setAnimate(true);
    }
  };

  const handleScratchedPercentChange = (percent: number) => {
    setScratchedPercent(percent);
    if (percent > 70) {
      handleScratch(true);
    }
  };

  // const handleClick = () => {
  //   setCover(false);
  //   setAnimate(true);
  //   setTimeout(() => {
  //     setShowModal(true);
  //   }, 5000);
  // };

  return (
    <>
      <IframeContainer>
        <StyledIframe src="https://betvera.com" />
      </IframeContainer>
      <Overlay />

      <MainWrapper>
        <Main>

          <Counter />
          <GlobalStyle />
          <Scratch>
            <BackgroundDiv>
              <Background onScratch={handleScratch} animate={animate} />
            </BackgroundDiv>
            <CardDiv>
              <ScratchCardDiv>
                {cover && (
                  <ScratchCard
                    width={350}
                    height={350}
                    image={Raspadinha}
                    brushSize={30}
                    onScratch={handleScratch}
                    onScratchedPercentChange={handleScratchedPercentChange}
                  />
                )}
              </ScratchCardDiv>
            </CardDiv>
          </Scratch>
          <ButtonDiv>
            {btn && (
              <button>
                <Btn>
                  <Hand
                    src={
                      "https://imagedelivery.net/mtEfDGS17P_NZufzQNbdqQ/11b50325-d462-4ca0-0180-9f627a55ec00/public"
                    }
                  />
                </Btn>
              </button>
            )}
            <StyledTag src={Tag} alt="Tag Image" />
          </ButtonDiv>
          {showModal && <Modal/>}

        </Main>
      </MainWrapper>
    </>
  );
};
export default App;
